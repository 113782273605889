import React from 'react'

import * as styles from '../custom.styles.module.css'
import { useLocal } from 'utils/useLocalContent'

const index = () => {
    return (
        <>
            <section className={styles.pageContainer}>
                <div className={styles.pageInnerCo}>
                    <div className={styles.videoWrapper}>
                        <video
                            className={styles.fullscreenVideo}
                            src="https://n0xtunjqxyj91w6z.public.blob.vercel-storage.com/valvie-website/videos/promo2C-vGhi8GeAyqaKb0xWZyrJpsE2AEt1z6.mp4"
                            autoPlay
                            loop
                            muted
                            playsInline
                        />
                    </div>
                </div>

                <div className={styles.textContainer}>
                    <p className={styles.textShowcase}>
                        <Text />
                    </p>
                </div>
            </section>
        </>
    )
}

export default index


const Text = () => {

    const lang = useLocal()
    return <>
        {lang === 'de' ? 'Zusammen mit Ihrer Vision und unserer Expertise heben wir das gesamte visuelle Erlebnis Ihrer Marke auf ein neues Niveau. Ob durch immersive 3D-Animationen, dynamische 2D-Videos oder fesselndes Live-Action-Material, wir erwecken jede Idee mit Kreativität, Präzision und Leidenschaft zum Leben. Mit unseren umfassenden Fähigkeiten und unserer Branchenerfahrung verwandeln wir Ihre Konzepte in fesselnde Realitäten, die bei Ihrem Publikum Anklang finden.' : 'Together with your vision and our expertise, we elevate your brand’s entire visual experience. Whether through immersive 3D animations, dynamic 2D videos, or engaging live-action footage, we bring every idea to life with creativity, precision, and passion. With our comprehensive skills and industry experience, we transform your concepts into captivating realities that resonate with your audience.'}
    </>
}